import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; Tilmann Glaser 2019</li>
        <li>
          Website created with <a href="https://www.gatsbyjs.org/">Gatsby</a> using the <a href="https://www.gatsbyjs.org/starters/anubhavsrivastava/gatsby-starter-spectral/">Spectral Starter</a>
        </li>
      </ul>
    </footer>
  );
}
