module.exports = {
  siteTitle: 'TGnowldegy.me', // <title>
  manifestName: 'TGnowledgy - Tilmann Glaser',
  manifestShortName: 'TGnowledgy', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#000059',
  manifestThemeColor: '#000059',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo_100x100.png',
  //pathPrefix: `/gatsby-starter-spectral/`, --> Not used since we're ona root page // This path is subpath of your hosting https://domain/portfolio
  heading: 'TGnowledgy - Tilmann Glaser',
  subHeading: 'Software Craftsman, Software Architect',
  subHeading2: 'Mentor for Engineering, Servant Leadership and Organisational Transformation',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/TGnowledgy',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:tilmann@tgnowledgy.me',
    },
    {
      style: 'brands',
      icon: 'fa-xing',
      name: 'Xing',
      url: 'https://www.xing.com/profile/Tilmann_Glaser/cv',
    },
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/tglaser',
    },
  ],
};
